var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-3",attrs:{"flat":""}},[_c('v-card-text',[_c('v-row',{staticClass:"class-padding",staticStyle:{"min-height":"79px"}},[_c('v-col',{attrs:{"cols":"7"}},[_c('v-row',[_c('v-radio-group',{attrs:{"label":"Tipo da nota:","row":""},model:{value:(_vm.tipoNota),callback:function ($$v) {_vm.tipoNota=$$v},expression:"tipoNota"}},[_c('v-radio',{attrs:{"label":"Troca","value":"troca"}})],1)],1)],1),_c('v-col',{attrs:{"cols":"3"}},[(_vm.tipoNota === 'devolucao')?_c('v-autocomplete',{attrs:{"items":_vm.searchNFE,"item-text":"T016_NumeroDocumento","item-value":"T016_NumeroDocumento","label":"Número nota fiscal","loading":_vm.loadingNFE,"dense":""},model:{value:(_vm.numberNFE),callback:function ($$v) {_vm.numberNFE=$$v},expression:"numberNFE"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"2"}},[(_vm.tipoNota === 'devolucao')?_c('v-btn',{staticClass:"primary",attrs:{"disabled":!_vm.numberNFE},on:{"click":_vm.getObjectNFE}},[_vm._v("Buscar ")]):_vm._e()],1)],1),(_vm.tipoNota === 'troca')?_c('v-row',{staticClass:"class-padding"},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-autocomplete',{attrs:{"items":_vm.livroOptions,"item-text":"nome","item-value":"id","label":"Livro","return-object":"","clearable":"","hide-details":"","dense":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nome)+" - "+_vm._s(item.edicao)+" ")]}}],null,false,2422282326),model:{value:(_vm.livroSelecionado),callback:function ($$v) {_vm.livroSelecionado=$$v},expression:"livroSelecionado"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-currency-field',{attrs:{"autocomplete":"new","name":"new","label":"Valor unitário","dense":""},model:{value:(_vm.valorUnitario),callback:function ($$v) {_vm.valorUnitario=$$v},expression:"valorUnitario"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"label":"Quantidade","type":"number","rules":[
            function (value) { return value >= 0 || 'A quantidade deve ser maior ou igual a 0'; }
          ],"dense":""},model:{value:(_vm.quantidade),callback:function ($$v) {_vm.quantidade=_vm._n($$v)},expression:"quantidade"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{attrs:{"items":['300', '400'],"label":"CST","dense":""},model:{value:(_vm.cst),callback:function ($$v) {_vm.cst=$$v},expression:"cst"}})],1),_c('v-col',{attrs:{"cols":"10"}},[_c('v-textarea',{attrs:{"label":"Observação","rows":"3"},model:{value:(_vm.observacao),callback:function ($$v) {_vm.observacao=$$v},expression:"observacao"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"primary",attrs:{"disabled":!_vm.livroSelecionado || !_vm.valorUnitario},on:{"click":_vm.handleAddItemNFE}},[_vm._v(" Adicionar ")])],1)],1):_vm._e(),_c('v-data-table',{staticClass:"mt-4",attrs:{"headers":_vm.headersNFE,"items":_vm.itemsNFE,"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.quantidade",fn:function(ref){
          var item = ref.item;
return [_c('v-text-field',{staticClass:"class-input white rounded-xl",staticStyle:{"max-width":"120px"},attrs:{"hide-details":"","outlined":"","filled":"","dense":""},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{on:{"click":function($event){return _vm.decrementQuantity(item)}}},[_vm._v("mdi-minus")])]},proxy:true},{key:"append",fn:function(){return [_c('v-icon',{on:{"click":function($event){return _vm.incrementQuantity(item)}}},[_vm._v("mdi-plus")])]},proxy:true}],null,true),model:{value:(item.quantidade),callback:function ($$v) {_vm.$set(item, "quantidade", $$v)},expression:"item.quantidade"}})]}},{key:"item.valor_unitario",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatPriceValueWithoutDefault")(item.valor_unitario))+" ")]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.deleteNFE(item)}}},[_vm._v("mdi-delete")])]}}],null,true)})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","disabled":_vm.itemsNFE.length === 0,"loading":_vm.loadingSave},on:{"click":_vm.handleSaveNFE}},[_vm._v("Salvar")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }