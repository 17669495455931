var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"ma-1 pa-3 rounded-xl mb-3"},[_c('v-tabs',{attrs:{"background-color":"primary","center-active":"","dark":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{key:"nfe"},[_vm._v(" NFE ")]),_c('v-tab',{key:"troca/devolucao"},[_vm._v(" Troca/Devolução ")]),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{key:"nfe"},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',{staticClass:"headline primary--text"},[_vm._v("NFe")])]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-radio-group',{attrs:{"row":""},model:{value:(_vm.radioGroup),callback:function ($$v) {_vm.radioGroup=$$v},expression:"radioGroup"}},[_c('v-radio',{attrs:{"label":"Por Vencimento","value":"V"}}),_c('v-radio',{attrs:{"label":"Por Emissão","value":"E"}})],1)],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('PeriodSelectorComponent',{attrs:{"propTitle":_vm.radioGroup === 'V' ? 'Vencimento' : 'Emissão'},model:{value:(_vm.selectPeriodDate),callback:function ($$v) {_vm.selectPeriodDate=$$v},expression:"selectPeriodDate"}})],1),_c('v-col',[_c('v-btn',{staticClass:"primary",attrs:{"loading":_vm.loading},on:{"click":function($event){return _vm.buscar()}}},[_vm._v(" buscar ")])],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"primary",attrs:{"loading":_vm.loadingGerandoXml},on:{"click":function($event){return _vm.handleGerarXmlPeriodo()}}},'v-btn',attrs,false),on),[_vm._v(" Gerar Xml por periodo ")])]}}])},[_c('span',[_vm._v("Irá gerar notas de trocas também")])])],1)],1),_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-title',[_vm._v("Totalizadores por status")]),_c('v-card-text',[_c('v-row',[_c('v-col',[(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"table"}}):_c('v-data-table',{ref:"totalizadorCategoria",attrs:{"headers":_vm.porStatusHeaders,"items":_vm.porStatusNFItems,"item-key":"origem","footer-props":{ itemsPerPageOptions: [-1] },"hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',[_vm._l((items),function(item){return _c('tr',{key:item.origem},[_c('td',[_c('v-checkbox',{attrs:{"value":item.origem,"hide-details":"","dense":""},model:{value:(_vm.selectedStatuses),callback:function ($$v) {_vm.selectedStatuses=$$v},expression:"selectedStatuses"}})],1),_c('td',[_vm._v(_vm._s(item.origem))]),_c('td',[_vm._v(_vm._s(_vm._f("formatPriceValue")(item.valor)))]),_c('td',[_vm._v(_vm._s(item.quantidade))])])}),_c('tr',[_c('td',[_c('b',[_vm._v("Totais")])]),_c('td'),_c('td',[_c('b',[_vm._v(_vm._s(_vm._f("formatPriceValue")(_vm.sumField(_vm.porStatusNFItems, "valor"))))])]),_c('td',[_c('b',[_vm._v(_vm._s(_vm.sumField(_vm.porStatusNFItems, "quantidade")))])])])],2)]}}]),model:{value:(_vm.selectedStatuses),callback:function ($$v) {_vm.selectedStatuses=$$v},expression:"selectedStatuses"}})],1)],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('imprimir-tabela-component',{staticStyle:{"float":"right"},attrs:{"propRef":[_vm.tabelaRef],"propDashboardFinanceiro":true}}),_c('v-text-field',{attrs:{"label":"Buscar","single-line":"","append-icon":"mdi-magnify","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{ref:"tabela",attrs:{"headers":_vm.headers,"items":_vm.filterItems,"loading":_vm.loading,"search":_vm.search,"loading-text":"Carregando...","footer-props":{ itemsPerPageOptions: [-1] },"hide-default-footer":"","multi-sort":"","dense":""},scopedSlots:_vm._u([{key:"item.data_vencimento",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.data_vencimento))+" ")]}},{key:"item.plugnotas_data_emissao",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.plugnotas_data_emissao))+" ")]}},{key:"item.valor_integral",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatPriceValue")(item.valor_integral))+" ")]}},{key:"item.total_pago",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatPriceValue")(item.total_pago))+" ")]}},{key:"item.aluno_nome",fn:function(ref){
var item = ref.item;
return [(item.aluno_id)?_c('OpenNewTabComponent',{attrs:{"propObjectLabel":("" + (item.aluno_nome)),"propObjectId":item.aluno_id,"propToolName":'Aluno'}}):_vm._e(),_c('v-chip',{class:_vm._.find(
                        _vm.getStatusDoAlunoOptions(),
                        function (s) { return s.id == item.contrato_status_id; }
                      ).class,attrs:{"label":""}},[_vm._v(" "+_vm._s(_vm._.find( _vm.getStatusDoAlunoOptions(), function (s) { return s.id == item.contrato_status_id; } ).name)+" ")])]}},{key:"item.plugnotas_id",fn:function(ref){
                      var item = ref.item;
return [(
                        item.plugnotas_id || item.contrato_status_id !== 6
                      )?_c('PlugnotasConsultaEmiteButton',{attrs:{"propPlugnotasId":item.plugnotas_id,"propPlugnotasStatus":item.plugnotas_status,"propFinanceiroId":item.id,"propTipoDocumento":3}}):_vm._e()]}}],null,true)})],1)],1)],1)],1),_c('v-tab-item',{key:"troca/devolucao"},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',{staticClass:"headline primary--text"},[_vm._v("NFe Troca/Devolução")]),_c('v-spacer'),_c('v-btn',{staticClass:"success",on:{"click":_vm.showNFE}},[_vm._v(" Gerar ")])],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('PeriodSelectorComponent',{attrs:{"propTitle":"Emissão"},model:{value:(_vm.selectPeriodDate),callback:function ($$v) {_vm.selectPeriodDate=$$v},expression:"selectPeriodDate"}})],1),_c('v-col',[_c('v-btn',{staticClass:"primary",attrs:{"loading":_vm.loading},on:{"click":function($event){return _vm.buscarNFeTrocaDevolucao()}}},[_vm._v(" buscar ")])],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headersNFeTrocaDevolucao,"items":_vm.notaFiscaisNfes,"loading":_vm.loading,"search":_vm.search,"loading-text":"Carregando...","footer-props":{ itemsPerPageOptions: [-1] },"hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:"item.plugnotas_data_emissao",fn:function(ref){
                      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.plugnotas_data_emissao))+" ")]}},{key:"item.items",fn:function(ref){
                      var item = ref.item;
return [_c('v-simple-table',{staticClass:"my-2 blue-grey lighten-5",attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',{staticClass:"articulat-extrabold"},[_c('th',[_vm._v("Livro")]),_c('th',[_vm._v("Quantidade")]),_c('th',[_vm._v("Valor")])])]),_c('tbody',_vm._l((item.items),function(book,idx){return _c('tr',{key:idx},[_c('td',[_vm._v(_vm._s(book.descricao_produto))]),_c('td',[_vm._v(_vm._s(book.quantidade))]),_c('td',[_vm._v(_vm._s(book.valor_unitario))])])}),0)]},proxy:true}],null,true)})]}},{key:"item.plugnotas_id",fn:function(ref){
                      var item = ref.item;
return [(
                        item.plugnotas_id || item.contrato_status_id !== 6
                      )?_c('PlugnotasConsultaEmiteButton',{attrs:{"propPlugnotasId":item.plugnotas_id,"propPlugnotasStatus":item.plugnotas_status,"propFinanceiroId":item.id,"propTipoDocumento":4}}):_vm._e()]}}],null,true)})],1)],1)],1)],1)],1)],1)],1),_c('ShowPopup',{ref:"emissaoNFE",attrs:{"propTitle":"Emissão NFe","propWidth":"60%","propSmall":true}},[_c('DialogNFE',{on:{"on-confirm":function($event){return _vm.buscarNFeTrocaDevolucao()}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }