import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { livroStore, mainStore, plugnotasNfeStore } from '@/store';
let DialogNFE = class DialogNFE extends Vue {
    constructor() {
        super(...arguments);
        this.numberNFE = '';
        this.livroSelecionado = null;
        this.valorUnitario = 400.84;
        this.quantidade = 1;
        this.observacao = '';
        this.tipoNota = 'troca';
        this.cst = '300';
        this.itemsNFE = [];
        this.loadingNFE = false;
        this.loadingSave = false;
        this.headersNFE = [
            { text: 'Livro', value: 'livro_nome' },
            { text: 'Valor', value: 'valor_unitario' },
            { text: 'Quantidade', value: 'quantidade' },
            { text: 'Ação', value: 'actions' },
        ];
    }
    get searchNFE() {
        return plugnotasNfeStore.notaFiscal.sort((a, b) => {
            return Number(a.T016_NumeroDocumento) < Number(b.T016_NumeroDocumento) ? 1 : -1;
        });
    }
    get livroOptions() {
        return livroStore.livrosEGuides;
    }
    getObjectNFE() {
        const item = this.searchNFE;
        this.itemsNFE = item.filter((f) => f.T016_NumeroDocumento === this.numberNFE)[0].items;
        this.itemsNFE.forEach((itemNFE) => {
            itemNFE.T012_Quantidade = 0;
        });
    }
    handleAddItemNFE() {
        const items = {
            livro_nome: `${this.livroSelecionado.nome} - ${this.livroSelecionado.edicao}`,
            codigo_nectar: this.livroSelecionado.codigo_nectar,
            valor_unitario: this.valorUnitario,
            quantidade: this.quantidade,
        };
        this.itemsNFE.push(items);
        this.livroSelecionado = null;
    }
    async handleSaveNFE() {
        this.loadingSave = true;
        const items = this.itemsNFE.map((i) => {
            const item = {
                codigo_produto: i.codigo_nectar,
                descricao_produto: i.livro_nome,
                valor_unitario: i.valor_unitario,
                quantidade: i.quantidade,
            };
            return item;
        });
        const payload = {
            tipo_nota: this.tipoNota,
            itens: items,
            observacao: this.observacao,
            cst: this.cst || '300'
        };
        await plugnotasNfeStore.plugnotasNfeEmitirTroca(payload);
        this.loadingSave = false;
        this.$emit('on-confirm');
    }
    deleteNFE(item) {
        const index = this.itemsNFE.indexOf(item);
        if (index !== -1) {
            this.itemsNFE.splice(index, 1);
        }
    }
    incrementQuantity(item) {
        item.quantidade++;
    }
    decrementQuantity(item) {
        if (item.quantidade > 0) {
            item.quantidade--;
        }
    }
    async mounted() {
        const escolaId = mainStore.userProfile.company.escola_id;
        await livroStore.getLivrosEGuides({ escolaId });
    }
};
DialogNFE = __decorate([
    Component
], DialogNFE);
export default DialogNFE;
